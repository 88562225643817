import React from 'react';
import PrivateRoute from 'shared/auth/private-route';
import SiloOrderForm from './SiloOrderForm';
import SiloOrders from './siloOrders';
import { Redirect, Route, Switch } from 'react-router-dom';

export const readSiloOrdersRight = 'READ:Order';

export const siloOrdersTo = '/orders';
export const newSiloOrdersTo = '/orders/new';

const Routes = () => (
  <Switch>
    <Route exact path="/silo-orders">
      <Redirect to={siloOrdersTo} />
    </Route>
    <Route exact path="/silo-orders/new">
      <Redirect to={newSiloOrdersTo} />
    </Route>
    <PrivateRoute
      path={newSiloOrdersTo}
      exact
      component={SiloOrderForm}
      hasRight={readSiloOrdersRight}
    />
    <PrivateRoute
      path={siloOrdersTo}
      exact
      component={SiloOrders}
      hasRight={readSiloOrdersRight}
    />
  </Switch>
);

export default Routes;
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { IRootState } from 'config/store';
import { FormContext, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IProduct } from 'shared/model/product.model';
import MultipleAutoComplete from 'shared/widgets/form/multipleAutocomplete';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { createProduct, updateProduct } from 'shared/reducers/productSlice';
import { IGroup } from 'shared/model/group.model';
// import { createProduct, updateProduct } from 'shared/reducers/productSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '400px',
        minHeight: '150px'
      }
    },
    autoComplete: {
      flexGrow: 2
    }
  })
);

export interface ICreateOrEditProductDialogProps {
  product?: IProduct
  onSuccess?: Function;
}
interface IFormResponse {
  device_content: string,
  device_content_reference: string,
  contract_number: string,
  groups: ILabelValueOption<IGroup['group_id']>[];
}

const CreateOrEditProductDialog = (props: ICreateOrEditProductDialogProps) => {
  const isNew = props.product?.id ? false : true;
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useForm<IFormResponse>({
    defaultValues: {
      device_content: isNew ? '' : props.product?.device_content,
      device_content_reference: isNew ? '' : props.product?.device_content_reference,
      contract_number: isNew ? '' : props.product?.contract_number,
      groups: isNew ? [] : props.product?.groups.map(group => ({ label: group.group_name, value: group.group_id }))
    }
  });
  const dispatch = useDispatch();
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const updating = useSelector(({ product }: IRootState) => product.updating);
  const updateSuccess = useSelector(({ product }: IRootState) => product.updateSuccess);
  const groups = useSelector(({ group }: IRootState) => group.groups);

  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (updateSuccess === true) {
      setOpen(false);
    }
  }, [updateSuccess]);

  const groupOptions: ILabelValueOption<IGroup['group_id']>[] = groups.map(aGroup => ({ label: aGroup.group_name, value: aGroup.group_id }));

  const onSubmit = form.handleSubmit(values => {

    const payload = {
      device_content: values.device_content,
      device_content_reference: values.device_content_reference,
      contract_number: values.contract_number,
      group_ids: values?.groups?.map((g) => g.value)
      // contract_number?: string;
    }
    if (!props.product?.id) {
      // create product
      dispatch(createProduct({ ...payload }));
    } else {
      dispatch(updateProduct({ ...payload, id: props.product.id }));
    }
    if (props.onSuccess) {
      props.onSuccess();
    }
  });

  const titleKey = isNew ? 'create_product' : 'edit_product';

  return (
    <>
      <Button variant="contained" color="primary" size="small" onClick={toggleDialog}>
        {isNew ? <AddIcon /> : <EditIcon />}
      </Button>
      <Dialog fullScreen={fullScreen} open={open} onClose={toggleDialog}>
        <FormContext {...form}>
          <form className={classes.root} onSubmit={onSubmit} autoComplete="off">
            <DialogTitle>{t(titleKey)}</DialogTitle>
            <DialogContent className={classes.content} dividers>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t('name')}
                fullWidth
                name="device_content"
                disabled={updating}
                inputRef={form.register({ required: <Trans i18nKey="required_field">Required Field</Trans> })}
                error={form.errors.device_content ? true : false}
                helperText={form.errors.device_content && form.errors.device_content.message}
              />
              <TextField
                margin="dense"
                id="device_content_reference"
                label={t('reference')}
                fullWidth
                name="device_content_reference"
                disabled={updating}
                inputRef={form.register({ required: <Trans i18nKey="required_field">Required Field</Trans> })}
                error={form.errors.device_content ? true : false}
                helperText={form.errors.device_content && form.errors.device_content.message}
              />
              {(settings.find(s => s.key === 'deviceContentWithContractNumber')?.value === 'true') &&
                <TextField
                  margin="dense"
                  id="contract_number"
                  label={t('contract_number')}
                  fullWidth
                  name="contract_number"
                  disabled={updating}
                  inputRef={form.register({ required: <Trans i18nKey="required_field">Required Field</Trans> })}
                  error={form.errors.device_content ? true : false}
                  helperText={form.errors.device_content && form.errors.device_content.message}
                />
              }
              {(settings.find(s => s.key === 'filterContentByGroup')?.value === 'true') &&
                <MultipleAutoComplete
                  label={t('availability')}
                  name="groups"
                  options={groupOptions}
                  className={classes.autoComplete}
                />}
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog} startIcon={<CancelIcon />}>
                {t('cancel')}
              </Button>
              <Button type="submit" color="primary" startIcon={<SaveIcon />}>
                {t('save')}
              </Button>
            </DialogActions>
          </form>
        </FormContext>
      </Dialog>
    </>
  );
};

export default CreateOrEditProductDialog;

import Box from '@material-ui/core/Box';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import DataTable from 'shared/widgets/dataTable';
import { useTranslation } from 'react-i18next';
import { IProduct } from 'shared/model/product.model';
import { IRootState } from 'config/store';
import { deleteProduct, fetchProducts } from 'shared/reducers/productSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import PrivateComponent from 'shared/auth/privateComponent';
import ConfirmDelete from 'shared/widgets/confirmDelete';
import ProductGroups from './ProductGroups';
import CreateOrEditProductDialog from './dialogs/createOrEditProductDialog';
import ProductDetails from './productDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextToolBar: {
      '&>*': {
        marginLeft: theme.spacing(1)
      }
    }
  })
);

const Products = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const products = useSelector(({ product }: IRootState) => product.products);
  const loading = useSelector(({ product }: IRootState) => product.loading);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<IProduct[]>([]);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const dispatch = useDispatch();

  const columns: IDataTableColumn<IProduct>[] = useMemo(() => {
    const defs = [
      {
        selector: 'device_content',
        name: t('name'),
        sortable: true,
        grow: 1,
        format: (row: IProduct) => (
          <Box display="flex" alignItems="center">
            <Box p={1}>
              <Box fontWeight="fontWeightBold" data-tag="allowRowEvents">
                {row.device_content}
              </Box>
            </Box>
          </Box>
        )
      },
      {
        selector: 'device_content_reference',
        name: t('reference'),
        sortable: true,
        grow: 1,
        format: (row: IProduct) => (
          <Box display="flex" alignItems="center">
            <Box p={1}>
              <Box fontWeight="fontWeightBold" data-tag="allowRowEvents">
                {row.device_content_reference}
              </Box>
            </Box>
          </Box>
        )
      }
    ];
    if (settings.find(s => s.key === 'deviceContentWithContractNumber')?.value === 'true') {
      defs.push({
        selector: 'contract_number',
        name: t('contract_number'),
        sortable: true,
        grow: 1,
        format: (row: IProduct) => (
          <Box display="flex" alignItems="center">
            <Box p={1}>
              <Box fontWeight="fontWeightBold" data-tag="allowRowEvents">
                {row.contract_number}
              </Box>
            </Box>
          </Box>
        )
      });
    }
    if (settings.find(s => s.key === 'filterContentByGroup')?.value === 'true') {
      defs.push({
        selector: 'group_memberships',
        name: t('availability', { count: 2 }),
        grow: 4,
        hide: 'md',
        format: (row: IProduct) => {
          return (
            <Box display="flex" flexWrap="wrap">
              <Box>
                {row.groups.length < 4 ? (
                  <>
                    {row.groups.map(gr => (
                      <ProductGroups key={gr.group_id} product={row} group={gr} />
                    ))}
                  </>
                ) : (
                  <Box data-tag="allowRowEvents">{`${row.groups.length} ${t('group', { count: row.groups.length })}`}</Box>
                )}
              </Box>
            </Box>
          );
        }
      });
    }

    return defs as IDataTableColumn<IProduct>[];
  }, [t, settings]);

  const actions = (
    <PrivateComponent hasRight="CREATE:DeviceContent">
      <CreateOrEditProductDialog />
    </PrivateComponent>
  );
  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = async () => {
      for (let i = 0; i < selectedRows.length; i++) {
        await dispatch(deleteProduct(selectedRows[i]));
      }
      onSuccess();
      dispatch(fetchProducts());
    };

    const onSuccess = () => {
      setToggleCleared(!toggleCleared);
    };

    return (
      <Box className={classes.contextToolBar}>
        {selectedRows.length === 1 && (
          <PrivateComponent hasRight="UPDATE:DeviceContent">
            <CreateOrEditProductDialog product={selectedRows[0]} onSuccess={onSuccess} />
          </PrivateComponent>
        )}
        <PrivateComponent hasRight="DELETE:DeviceContent">
          <ConfirmDelete onConfirm={handleDelete} objectToReturn={selectedRows} size="small" />
        </PrivateComponent>
      </Box>
    );
  }, [classes.contextToolBar, selectedRows, toggleCleared, dispatch]);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <Box p={1}>
      <DataTable
        title={t('products')}
        columns={columns}
        data={products}
        selectableRows
        progressPending={loading}
        expandableRows
        expandableRowsComponent={<ProductDetails />}
        expandOnRowClicked
        expandableRowsHideExpander
        onSelectedRowsChange={handleRowSelected}
        actions={actions}
        contextActions={contextActions}
        clearSelectedRows={toggleCleared}
      />
    </Box>
  );
};

export default Products;
